import React from 'react';
import I18n from '../utils/I18n';
import Translation from '../utils/Translation';
//import reviewBoards from "../../data/reviewBoards.json";

const ArchReviewBoard = ({lang, items}) => {
  const i18n = new I18n({ lang });
  const translatedItems = Translation.getItems(items, [], 'identifier', lang);

  return (
    <div className="C(gray-light2) review-board-large">
      <div className="slds-grid slds-wrap slds-grid cs-exam-table__head">
        <div className="slds-col--padded slds-size--1-of-1 slds-medium-size--4-of-12 slds-text-heading--medium slds-medium-show">{i18n.text('reviewBoards_location')}</div>
        <div className="slds-col--padded slds-size--1-of-1 slds-medium-size--5-of-12 slds-text-heading--medium slds-medium-show">{i18n.text('reviewBoards_datesAvailable')}</div>
        <div className="slds-col--padded slds-size--1-of-1 slds-medium-size--3-of-12 slds-text-heading--medium slds-medium-show">{i18n.text('reviewBoards_status')}</div>
      </div>
      {translatedItems.map((data, index) => {
        return (
          <div key={`content_item_${index}`} className="slds-grid slds-wrap slds-grid cs-exam-table__line slds-m-top--medium">
            <div className="slds-col--padded slds-size--1-of-1 custom-medium-hide slds-text-heading--medium slds-m-top--xx-small">
              {i18n.text('reviewBoards_location')}
            </div>

            <div className="reviewBoards_row slds-col--padded slds-size--1-of-1 slds-medium-size--4-of-12">
              {data.title}
            </div>

            <div className="slds-col--padded slds-size--1-of-1 custom-medium-hide slds-text-heading--medium slds-m-top--xx-small">
              {i18n.text('reviewBoards_datesAvailable')}
            </div>

            <div className="reviewBoards_row slds-col--padded slds-size--1-of-1 slds-medium-size--5-of-12">
              {data.txt1}
            </div>

            <div className="slds-col--padded slds-size--1-of-1 custom-medium-hide slds-text-heading--medium slds-m-top--xx-small">
              {i18n.text('reviewBoards_status')}
            </div>

            <div className="reviewBoards_row slds-col--padded slds-size--1-of-1 slds-medium-size--3-of-12">
              {data.txt3}
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default ArchReviewBoard;
