// using CommonJS export because function is used in gatsby-node.js file and runs during node build
module.exports = {
  getItems: function(allData=[], listToShow=[], key='id', lang='en', langKey='lang') {
    const items = [];
    
    if(listToShow && listToShow.length > 0) {
      listToShow.forEach((listItem, index) => {
        const foundEN = allData.filter( it => 
          (it[key] == listItem || (key == 'slug' && it[key] == listItem.toSlug()))
          && it[langKey] == "en"
        );
        const foundByLang = allData.filter(it => 
          (it[key] == listItem || (key == 'slug' && it[key] == listItem.toSlug())) 
          && it[langKey] == lang
        );
        let item;

        if(foundEN.length > 0) {
          item = foundEN[0];
        }

        if(foundByLang.length > 0) {
          item = foundByLang[0];
        }
        if(item) {
          items.push(item);
        }
      });

    } else {
      const itemsEN = allData.filter(it => it[langKey] == "en");

      itemsEN.forEach((itemEN, index) => {
        let item = itemEN;
        const foundByLang = allData.filter(t => t[langKey] == lang && t[key] == itemEN[key]);

        if(foundByLang.length > 0) {
          item = foundByLang[0];
        }
      
        items.push(item);
      });
    }
    
    return items;
  }
}
