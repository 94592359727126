import React, { Fragment } from 'react';
import Layout from '../../layouts/Layout';
import ArchReviewBoard from '../../components/ArchReviewBoard';
import ArchitectReviewBanner from '../../components/ArchitectReviewBanner';

const architectreviewboard = () => {
    return (
        <Layout>
            {/* Overview page Banner */}
            <ArchitectReviewBanner/>

<div className="bg-image--gray-icons slds-p-bottom--x-large">
	<div className="slds-container--center slds-container--medium slds-m-bottom--x-large slds-is--relative Pt(1px)">
		<div className="box-overlaped-top cs-box-1">
			<div className="slds-text-align--left Fz(20px) slds-p-bottom_large Fw(b)">
				Completing the Certified Technical Architect Review Board Exam
			</div>

			<div className="slds-text-align--left Fz(18px) slds-p-bottom_large Fw(b)">
				Resources : Certified Technical Architect Review Board Exam
			</div>

			<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-bottom--large">
				Recommended Training and References
			</div>

			<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-bottom--large">
				<p>
					To prepare for this exam, Salesforce recommends a combination of hands-on experience, training course completion, Trailhead trails, Trailmixes, and self-study in each of the exam domain focus areas. Below are some key resources to assist you in your preparation:
				</p>
				&nbsp;

				<ul className="blank-bullets Lh(1.5) Fz(16px) slds-container--center">
					<li>
						<b>Trailhead Trailmix</b><br />
						The <a href="https://trailhead.salesforce.com/en/users/strailhead/trailmixes/architect-trailmix-master" target="__blank">Architect Journey Trailmix</a> is a compilation of architect domain-specific Trailmixes to help build, hone and practice your skills. Not only should you use these Trailmixes to help you prepare for the Architect Designer credential exams, we strongly recommend that you use them in your preparation for the CTA Review Board exam.
					</li>
					<li>
						<b>Certified Technical Architect Preparation Workshop</b><br />
						This participant-centered workshop will help you internalize the exam objectives while reinforcing your knowledge of key topics. Take a look at the available <a href="https://help.salesforce.com/HTTrainingCourseDetail?id=a230M0000001C0U" target="__blank">CTA-601 In-Person</a> or <a href="https://help.salesforce.com/HTTrainingCourseDetail?id=a230M000000A5UJ" target="__blank">CTA-601 Virtual</a> Schedules.
					</li>
					<li>
						<b>Architect Trailblazer Community</b><br />
						For additional information and community collaboration on your #JourneytoCTA, please join and leverage the <a href="https://success.salesforce.com/_ui/core/chatter/groups/GroupProfilePage?g=0F930000000blKv" target="__blank">Architect Trailblazer Community</a>. In the community you will find:
						<ul className="outside-bullets Lh(1.5) Fz(16px) slds-container--center slds-m-left--large slds-m-top--small">
							<li>
								Sample Scenarios
							</li>
							<li>
								Content links
							</li>
							<li>
								Self-paced learning
							</li>
							<li>
								Other related communities
							</li>
						</ul>
					</li>
				</ul>
			</div>

			<div className="slds-text-align--left Fz(18px) slds-p-bottom_large Fw(b)">
				The Exam : About the Certified Technical Architect Review Board Exam
			</div>

			<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-bottom--large">
				To earn the Salesforce Certified Technical Architect credential, you will need to successfully pass a Review Board exam. The prerequisites to initially qualify for this exam are System Architect and Application Architect. We do recommend that you set a study plan in place as well as obtaining extensive hands-on experience.
			</div>

			<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-bottom--large">
				How is this exam administered?
			</div>

			<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-bottom--large">
				<ul className="blank-bullets Lh(1.5) Fz(16px) slds-container--center">
					<li>
						<b>Virtual Review Board</b><br />
						This new format will allow you to take your exam online.
					</li>
					<li>
						<b>In-Person Review Board</b><br />
						This onsite format will require you to come into the selected office to take your exam.
					</li>
				</ul>
			</div>

			<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-bottom--large">
				About the Exam
			</div>

			<ul className="outside-bullets Lh(1.5) Fz(16px) slds-container--center">
				<li>
					Candidates are given 3 hours (180 minutes) to read, solution, design and justify a detailed architectural solution for the hypothetical scenario.
				</li>
				<li>
					Candidates will then have 45 minutes to present their solution to the Review Board judges. A Q&amp;A discussion will then be held by the judges for another 40 minutes.
				</li>
				<li>
					Registration fee: USD 6,000 plus applicable taxes as required per local law
				</li>
				<li>
					Retake fee: USD 3,000, plus applicable taxes as required per local law
				</li>
			</ul>

			<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-vertical--large">
				For additional information, download the <a href="https://trailhead.salesforce.com/help?article=Salesforce-Certified-Technical-Architect-Exam-Guide" target="__blank">exam guide</a> or read our <a href="https://trailhead.salesforce.com/help?article=Frequently-Asked-Questions-Salesforce-Architect-Program" target="__blank">FAQ</a>.
			</div>

			<div className="slds-m-bottom--large">
				<div className="slds-text-align--left Fz(20px) slds-p-bottom_large Fw(b) slds-p-bottom_large">
					How to Register for your #JourneytoCTA
				</div>

				<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-bottom--large">
					Open a Case
				</div>

				<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center ">
					Ready to start your journey to becoming a Certified Technical Architect? Great! Now that you have both your domain Architect credentials, you&rsquo;ll need to get your preparation plan defined. The best way to do that is to <a href="https://trailhead.salesforce.com/help?support=home" target="_blank">open a case</a> through Trailhead. We will process your case, send you a short questionnaire to fill out so that we can support you in the way that you need! You will then be contacted and given a prescriptive recommendation.
				</div>
			</div>

			<div className="slds-text-align--left Fz(20px) slds-p-bottom_large Fw(b) slds-p-bottom_large">
				Review Board Exam Schedule
			</div>

			<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-bottom--large">
				The Certified Technical Architect Review Board exam schedule is below. This exam is administered either virtually or in-person.
			</div>

			<div className="slds-text-align--left Lh(1.5) Fz(16px) slds-container--center slds-p-bottom--large">
				For additional information, <a href="https://trailhead.salesforce.com/help?support=home">contact us</a>.
			</div>
			<ArchReviewBoard/>
			<div className="C(gray2) slds-p-top_large slds-p-bottom--small">
				NOTES
			</div>

			<ul className="outside-bullets C(gray2) slds-p-bottom_large">
				<li>
					Exam dates and locations are subject to change at the discretion of Trailhead.
				</li>
				<li>
					Review the resources above for more information.
				</li>
				<li>
					Please open a <a href="https://trailhead.salesforce.com/help?support=home" target="_blank">case</a> to get started!
				</li>
			</ul>
		</div>
	</div>
</div>
</Layout>
    );

}

export default architectreviewboard;
